import React from 'react'
import HeaderOfAds from '../Header/HeaderOfAds'
import Main from '../Main/Main'
const Search = () => {

  return (
     <>
    <HeaderOfAds />
    <Main /> 
    </>
  )
}

export default Search