import React from 'react'
import HeaderOfAds from '../Header/HeaderOfAds';
import MainOfAds from '../Main/MainOfAds';
const Ads = () => {
  return (
    <>
    <HeaderOfAds />
    <MainOfAds />
  </>
  )
}

export default Ads;